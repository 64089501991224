import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import operator from '../../images/SOperator.jpg';
import '../croos/button.css';
import { useNavigate } from 'react-router-dom';

const SSection5 = () => {
    const languageData = useContext(LanguageContext);
    const navigate = useNavigate();

    const handleButtonClick = () => {
        const currentLang = languageData.SEO.language;
        const serviceUrl = currentLang === 'en' ? '/contact' : `/${currentLang}/contact`;
        navigate(serviceUrl);
    };

    // Añadir margen superior en modo móvil
    const mobileImageStyle = window.innerWidth < 768 ? { marginTop: '20px' } : {};

    return (
        <div id="ssection5" className="container-fluid text-center" style={{ marginTop: '20rem' }}>
            <div className="container py-4">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-start h-100">
                            <h1 style={{
                                fontSize: window.innerWidth >= 768 ? '60px' : '40px',
                                textAlign: 'left'
                            }}><b>{languageData.services.s5.h}</b></h1>
                            <br />
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s5.p}</p>
                            <div className="text-start">
                                <button type="button" className="btn-custom-success mt-4" onClick={handleButtonClick}>{languageData.services.s1.b}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={mobileImageStyle}>
                        <img src={operator} alt="operator" style={{ width: '100%', borderRadius: '5%' }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SSection5;
