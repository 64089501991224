import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from './croos/getintouch';
import { LanguageContext } from '../LanguageContext';
import SPartners1 from './partners/spartners1';

const Partners = () => {
    const languageData = useContext(LanguageContext);
    return (
        <>
            <Helmet>
                <html lang={languageData.partners.SEO.language} />
                <title>{languageData.partners.SEO.title}</title>
                <meta charSet="utf-8" />
                <link rel="manifest" href="manifest.json" />
                <meta name="description" content={languageData.partners.SEO.meta.description} />
                <meta name="viewport" content={languageData.partners.SEO.meta.viewport} />
                <link rel="icon" href="favicon.ico" />
            </Helmet>
            <div style={{ marginTop: '90px' }}>
                <SPartners1 />
                <GetInTouch />
            </div>
        </>
    );
};

export default Partners;