import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import SSection1 from './services/ssection1';
import SSection2 from './services/ssection2';
import SSection3 from './services/ssection3';
import SSection4 from './services/ssection4';
import SSection5 from './services/ssection5';
import SSection6 from './services/ssection6';
import SSection7 from './services/ssection7';
import SSection8 from './services/ssection8';

import GetInTouch from './croos/getintouch';
import { LanguageContext } from '../LanguageContext';

const Services = () => {
    const languageData = useContext(LanguageContext);
    return (
        <>
            <Helmet>
                <html lang={languageData.services.SEO.language} />
                <title>{languageData.services.SEO.title}</title>
                <meta charSet="utf-8" />
                <link rel="manifest" href="manifest.json" />
                <meta name="description" content={languageData.services.SEO.meta.description} />
                <meta name="viewport" content={languageData.services.SEO.meta.viewport} />
                <link rel="icon" href="favicon.ico" />
            </Helmet>
            <div style={{ marginTop: '90px' }}>
                <SSection1 />
                <SSection2 />
                <SSection3 />
                <SSection4 />
                <SSection5 />
                <SSection6 />
                <SSection7 />
                <SSection8 />
                <GetInTouch />
            </div>
        </>
    );
};

export default Services;