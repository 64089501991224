import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import working from '../../images/working.jpg';
import { useNavigate } from 'react-router-dom';
import '../croos/button.css';

const Section3 = () => {
    const languageData = useContext(LanguageContext);
    const navigate = useNavigate();

    const handleButtonClick = () => {
        const currentLang = languageData.SEO.language;
        const serviceUrl = currentLang === 'en' ? '/services#ssection1' : `/${currentLang}/services#ssection1`;
        navigate(serviceUrl);
    };

    const adjustTextSize = () => {
        const newSize = window.innerWidth >= 768 ? '60px' : '40px';
        document.querySelectorAll('.dynamic-text-size').forEach(element => {
          element.style.fontSize = newSize;
        });
      };
    
      useEffect(() => {
        adjustTextSize();
        window.addEventListener('resize', adjustTextSize);
    
        return () => window.removeEventListener('resize', adjustTextSize);
      }, []);

    return (
        <div id="ssection3" className="container-fluid text-center" style={{ marginTop: '20rem' }}>
            <div className="container py-4">
                <div className="row align-items-center">
                    <div className="col-md-6 order-md-1">
                        <div className="d-flex flex-column align-items-start h-100">
                            <h2 className="dynamic-text-size"><b>{languageData.home.s3.h}</b></h2>
                            <p className="mt-4" style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.home.s3.p}</p>
                            <div className="text-start mt-4"> {/* Alinea el botón a la izquierda */}
                                <button type="button" className="btn-custom-success" onClick={handleButtonClick}>{languageData.home.s3.b}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-md-2 mt-md-0 mt-4"> {/* Añade margen superior solo en pantallas pequeñas */}
                        <img src={working} alt="Working" style={{ width: '80%', borderRadius: '5%' }} />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col text-center">
                        <div style={{ textAlign: 'left', display: 'inline-block', maxWidth: '100%' }}>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.home.s3.t1}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.home.s3.t2}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.home.s3.t3}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.home.s3.t4}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.home.s3.t5}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.home.s3.t6}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section3;
