import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import cowork from '../../images/cowork.jpg'
import '../croos/button.css';
import { useNavigate, useLocation  } from 'react-router-dom';

const SSection1 = () => {
    const languageData = useContext(LanguageContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                // Espera a que se cargue la página para calcular las posiciones correctamente
                setTimeout(() => {
                    let headerOffset = 70; // Ajuste para la versión de escritorio
                    if (window.innerWidth < 768) {
                        // Ajuste para la versión móvil, puedes necesitar calcular este valor dinámicamente
                        headerOffset = 100; // Asumiendo que el header es más grande en móvil
                    }

                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }, 300); // Retardo para asegurar que la página está completamente cargada
            }
        }
    }, [location]);
    
      

    const handleButtonClick = () => {
        // Obtén el código de idioma actual (p. ej., 'en', 'es', 'ca')
        const currentLang = languageData.SEO.language;
        // Construye la URL dependiendo del idioma
        const serviceUrl = currentLang === 'en' ? '/contact' : `/${currentLang}/contact`;
    
        // Redirecciona a la URL
        navigate(serviceUrl);
      };
    return (
        <div id="ssection1" className="container-fluid text-center" style={{ marginTop: '10rem' }}>
            <div className="container py-4">
                <div className="row align-items-center">
                    {/* Mover la imagen a la izquierda */}
                    <div className="col-md-6">
                        <img src={cowork} alt="cowork" style={{ width: '100%', borderRadius: '5%' }} />
                    </div>
                    {/* Mover el texto a la derecha */}
                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-start h-100">
                            <h1 style={{
                                fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')(),
                                textAlign: 'left'
                            }}><b>{languageData.services.s1.h}</b></h1>
                            <br />
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s1.p}</p>
                            <div className="text-start mt-4"> {/* Alinea el botón a la izquierda */}
                                <button type="button" className="btn-custom-success" onClick={handleButtonClick}>{languageData.services.s1.b}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-5">
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover" style={{ fontSize: '18px' }}>
                                <thead>
                                    <tr style={{ fontSize: '20px' }}>
                                        <th>{languageData.services.s1.t11}</th>
                                        <th>{languageData.services.s1.t21}</th>
                                        <th>{languageData.services.s1.t31}</th>
                                        <th>{languageData.services.s1.t41}</th>
                                        <th>{languageData.services.s1.t51}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{languageData.services.s1.t12}</td>
                                        <td>{languageData.services.s1.t22}</td>
                                        <td>{languageData.services.s1.t32}</td>
                                        <td>{languageData.services.s1.t42}</td>
                                        <td>{languageData.services.s1.t52}</td>
                                    </tr>
                                    <tr>
                                        <td>{languageData.services.s1.t13}</td>
                                        <td>{languageData.services.s1.t23}</td>
                                        <td>{languageData.services.s1.t33}</td>
                                        <td>{languageData.services.s1.t43}</td>
                                        <td>{languageData.services.s1.t53}</td>
                                    </tr>
                                    <tr>
                                        <td>{languageData.services.s1.t14}</td>
                                        <td>{languageData.services.s1.t24}</td>
                                        <td>{languageData.services.s1.t34}</td>
                                        <td>{languageData.services.s1.t44}</td>
                                        <td>{languageData.services.s1.t54}</td>
                                    </tr>
                                    <tr>
                                        <td>{languageData.services.s1.t15}</td>
                                        <td>{languageData.services.s1.t25}</td>
                                        <td>{languageData.services.s1.t35}</td>
                                        <td>{languageData.services.s1.t45}</td>
                                        <td>{languageData.services.s1.t55}</td>
                                    </tr>
                                    <tr>
                                        <td>{languageData.services.s1.t16}</td>
                                        <td>{languageData.services.s1.t26}</td>
                                        <td>{languageData.services.s1.t36}</td>
                                        <td>{languageData.services.s1.t46}</td>
                                        <td>{languageData.services.s1.t56}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SSection1;
