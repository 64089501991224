import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from './croos/getintouch';
import { LanguageContext } from '../LanguageContext';
import SContact1 from './contact/scontact1';

const Contact = () => {
    const languageData = useContext(LanguageContext);
    return (
        <>
            <Helmet>
                <html lang={languageData.contact.SEO.language} />
                <title>{languageData.contact.SEO.title}</title>
                <meta charSet="utf-8" />
                <link rel="manifest" href="manifest.json" />
                <meta name="description" content={languageData.contact.SEO.meta.description} />
                <meta name="viewport" content={languageData.contact.SEO.meta.viewport} />
                <link rel="icon" href="favicon.ico" />
            </Helmet>
            <div style={{ marginTop: '90px' }}>
                <SContact1 />
            </div>
        </>
    );
};

export default Contact;