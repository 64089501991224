// middleware/languageUtils.js

export function getBrowserLanguage() {
    const lang = (navigator.language || navigator.userLanguage).split('-')[0];

    switch(lang) {
        case 'ca':
            return 'ca_ES';
        case 'es':
            return 'es_ES';
        case 'en':
            return 'en_EN';
        default:
            return 'en_EN'; // Valor predeterminado
    }
}

export function loadLanguageFile(langCode) {
    switch (langCode) {
        case 'ca_ES':
            return import('../i18n/ca_ES.json');
        case 'es_ES':
            return import('../i18n/es_ES.json');
        case 'en_EN':
            return import('../i18n/en_EN.json');
        default:
            return import('../i18n/en_EN.json'); // Carga inglés como predeterminado si no se encuentra el idioma
    }
}