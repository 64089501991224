import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';

const Advise = () => {
    const languageData = useContext(LanguageContext);

    return (
        <div id="scontact1" className="container-fluid text-center" style={{ marginTop: '10rem' }}>
            <div className="container py-4">
                <div className="row">
                    {/* Mover la imagen a la izquierda */}
                    <div className="rounded" style={{ backgroundColor: '#f8f9fa' }}>
                        <h1 style={{ fontSize: '60px' }}><b>{languageData.advise.h}</b></h1>
                        <h2 className="mt-5" style={{ fontSize: '40px', textAlign: "left" }}><b>{languageData.advise.advise.h}</b></h2>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.advise.h1}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.advise.p1}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.advise.h2}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.advise.p2}</p>
                        <p style={{ fontSize: '18px', textAlign: "left" }}><b>{languageData.advise.advise.p21}</b></p>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.advise.p22}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.advise.h3}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.advise.p3}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.advise.h4}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.advise.p4}</p>
                        <h2 className="mt-5" style={{ fontSize: '40px', textAlign: "left" }}><b>{languageData.advise.politic.h}</b></h2>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h1}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p1}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h2}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p2}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h3}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p3}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h4}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p4}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h5}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p5}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h6}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p6}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h7}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p7}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h8}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p8}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.advise.politic.h9}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.advise.politic.p9}</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Advise;
