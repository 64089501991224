import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import alltime from '../../images/icons/247.png';
import validate from '../../images/icons/validate.png';
import optimize from '../../images/icons/optimize.png';
import value from '../../images/icons/value.png';
import operationale from '../../images/icons/operationalize .png';
import keys from '../../images/icons/key3.png';
import { useLocation  } from 'react-router-dom';


const SSection6 = () => {
    const languageData = useContext(LanguageContext);
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                let offsetPosition = element.getBoundingClientRect().top + window.pageYOffset;
    
                // Aplicar un offset mayor o un ajuste condicional para ssection6
                if (location.hash === '#ssection6') {
                    const customOffset = -250; // Ajusta este valor según sea necesario para ssection6
                    offsetPosition -= customOffset;
                } else if (location.hash === '#ssection1') {
                    const headerOffset = 70; // Offset para ssection1
                    offsetPosition -= headerOffset;
                }
    
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);
    
    return (
        <div id="ssection6" className="container-fluid text-center" style={{ marginTop: '20rem', backgroundColor: '#F7F7F7' }}>
            <div className="container" style={{ paddingTop: '10rem', paddingBottom: '10rem' }}>
                <div className="row align-items-center">
                    <div className="col mt-2">
                        <h2 style={{ fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')() }}><b>{languageData.services.s6.h}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: 'right' }}>{languageData.services.s6.p}</p>
                        <p style={{ fontSize: '18px', textAlign: 'right' }}><b>{languageData.services.s6.p2}</b></p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col mb-2">
                        <div className="card" style={{ width: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={alltime} className="card-img-top" alt="24/7" style={{ width: '12rem', margin: 'auto' }} />
                            <div className="card-body">
                                <h5 className="card-title">{languageData.services.s6.ch1}</h5>
                                <p className="card-text">{languageData.services.s6.cp1}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col mb-2">
                        <div className="card" style={{ width: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={validate} className="card-img-top" alt="24/7" style={{ width: '12rem', margin: 'auto' }} />
                            <div className="card-body">
                                <h5 className="card-title">{languageData.services.s6.ch2}</h5>
                                <p className="card-text">{languageData.services.s6.cp2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col mb-2">
                        <div className="card" style={{ width: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={optimize} className="card-img-top" alt="24/7" style={{ width: '12rem', margin: 'auto' }} />
                            <div className="card-body">
                                <h5 className="card-title">{languageData.services.s6.ch3}</h5>
                                <p className="card-text">{languageData.services.s6.cp3}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col mb-2">
                        <div className="card" style={{ width: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={value} className="card-img-top" alt="24/7" style={{ width: '12rem', margin: 'auto' }} />
                            <div className="card-body">
                                <h5 className="card-title">{languageData.services.s6.ch4}</h5>
                                <p className="card-text">{languageData.services.s6.cp4}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col mb-2">
                        <div className="card" style={{ width: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={operationale} className="card-img-top" alt="24/7" style={{ width: '12rem', margin: 'auto' }} />
                            <div className="card-body">
                                <h5 className="card-title">{languageData.services.s6.ch5}</h5>
                                <p className="card-text">{languageData.services.s6.cp5}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col mb-2">
                        <div className="card" style={{ width: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={keys} className="card-img-top" alt="24/7" style={{ width: '12rem', margin: 'auto' }} />
                            <div className="card-body">
                                <h5 className="card-title">{languageData.services.s6.ch6}</h5>
                                <p className="card-text">{languageData.services.s6.cp6}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SSection6;