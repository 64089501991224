import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';

// Importa los logos
import logo1 from '../../images/partners/cloudflare.svg';
import logo2 from '../../images/partners/crowdstrike.svg';
import logo3 from '../../images/partners/microsoft.svg';
import logo4 from '../../images/partners/wazuh.svg';
import logo5 from '../../images/partners/semperis.svg';
import logo6 from '../../images/partners/picus.svg';
import logo7 from '../../images/partners/perception-point.png';
import logo8 from '../../images/partners/Memcyco_logo_1.svg';
import logo9 from '../../images/partners/Memcyco_logo_2.svg';

const SPartners1 = () => {
    const languageData = useContext(LanguageContext);

    const textStyle = {
        fontSize: '20px',
        textAlign: 'left',
        marginTop: '1rem', // Espacio después de la imagen
    };

    return (
        <div id="spartner1" className="container text-center" style={{ marginTop: '10rem' }}>
            <h1 style={{ fontSize: '60px' }}><b>{languageData.partners.s1.h}</b></h1>
            <p style={{ fontSize: '20px' }}>{languageData.partners.s1.p}</p>

            <div className="row mt-5">
                {/* Envuelve cada imagen y párrafo en un div con estilos de flexbox */}
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <img src={logo6} alt="Picus" style={{ width: '70%', maxHeight: '100px', objectFit: 'contain', marginTop: '30px' }} />
                    <p style={textStyle}>{languageData.partners.s1.pp1}</p>
                </div>
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <img src={logo4} alt="Wazuh" style={{ width: '90%', maxHeight: '100px', objectFit: 'contain', marginTop: '30px' }} />
                    <p style={textStyle}>{languageData.partners.s1.pp2}</p>
                </div>
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <img src={logo2} alt="Crowdstrike" style={{ width: '95%', maxHeight: '100px', objectFit: 'contain', marginTop: '30px' }} />
                    <p style={textStyle}>{languageData.partners.s1.pp3}</p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <img src={logo5} alt="Semperis" style={{ width: '100%', maxHeight: '100px', objectFit: 'contain', marginTop: '30px', marginLeft: '70%' }} />
                    <p style={textStyle}>{languageData.partners.s1.pp4}</p>
                </div>
                {/*<div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <img src={logo3} alt="Microsoft" style={{ width: '100%', maxHeight: '100px', objectFit: 'contain', marginTop: '30px' }} />
                    <p style={textStyle}>{languageData.partners.s1.pp5}</p>
                </div>*/}
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <img src={logo1} alt="Cloudflare" style={{ width: '65%', maxHeight: '100px', objectFit: 'contain', marginTop: '30px' }} />
                    <p style={textStyle}>{languageData.partners.s1.pp6}</p>
                </div>
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <div style={{
                        width: '65%',
                        maxHeight: '100px',
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'radial-gradient(ellipse at center, rgba(28, 25, 72, 0.2) 0%, rgba(255, 255, 255, 0) 78%)'
                    }}>
                        <img src={logo7} alt="Perception" style={{ width: '100%', objectFit: 'contain' }} />
                    </div>
                    <p style={textStyle}>{languageData.partners.s1.pp7}</p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <div style={{
                        width: '65%',
                        maxHeight: '100px',
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img src={logo8} alt="Memcyco1" style={{ width: '100%', objectFit: 'contain' }} />
                        &nbsp;
                        <img src={logo9} alt="Memcyco1" style={{ width: '100%', objectFit: 'contain' }} />
                    </div>
                    <p style={textStyle}>{languageData.partners.s1.pp8}</p>
                </div>
            </div>
        </div>
    );
};
export default SPartners1;
