import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';

const Cookies = () => {
    const languageData = useContext(LanguageContext);

    return (
        <div id="scontact1" className="container-fluid text-center" style={{ marginTop: '10rem' }}>
            <div className="container py-4">
                <div className="row">
                    {/* Mover la imagen a la izquierda */}
                    <div className="rounded" style={{ backgroundColor: '#f8f9fa' }}>
                        <h1 style={{ fontSize: '60px' }}><b>{languageData.cookiePolicy.title}</b></h1>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.cookiePolicy.introduction}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.cookiePolicy.purpose.title}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.cookiePolicy.purpose.description}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.cookiePolicy.consent.title}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.cookiePolicy.consent.description}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.cookiePolicy.managingCookies.title}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.cookiePolicy.managingCookies.description}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.cookiePolicy.changesToPolicy.title}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.cookiePolicy.changesToPolicy.description}</p>
                        <h2 className="mt-4" style={{ fontSize: '30px', textAlign: "left" }}><b>{languageData.cookiePolicy.moreInfo.title}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: "left" }}>{languageData.cookiePolicy.moreInfo.description}</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Cookies;