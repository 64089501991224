import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import missions from '../../images/missions.jpeg'
import '../croos/button.css';
import { useNavigate } from 'react-router-dom';

const SSection3 = () => {
    const languageData = useContext(LanguageContext);
    const navigate = useNavigate();
    const handleButtonClick = () => {
        // Obtén el código de idioma actual (p. ej., 'en', 'es', 'ca')
        const currentLang = languageData.SEO.language;
        // Construye la URL dependiendo del idioma
        const serviceUrl = currentLang === 'en' ? '/contact' : `/${currentLang}/contact`;
    
        // Redirecciona a la URL
        navigate(serviceUrl);
      };
    return (
        <div id="ssection3" className="container-fluid text-center" style={{ marginTop: '20rem' }}>
            <div className="container py-4">
                <div className="row align-items-center">
                    {/* Mover la imagen a la izquierda */}
                    <div className="col-md-6">
                        <img src={missions} alt="mission" style={{ width: '100%', borderRadius: '5%' }} />
                    </div>
                    {/* Mover el texto a la derecha */}
                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-start h-100">
                            <h1 style={{
                                fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')(),
                                textAlign: 'left'
                            }}><b>{languageData.services.s3.h}</b></h1>
                            <br />
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s3.p1}</p>
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s3.p2}</p>
                            <div className="text-start"> {/* Alinea el botón a la izquierda */}
                                <button type="button" className="btn-custom-success mt-4" onClick={handleButtonClick}>{languageData.services.s1.b}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SSection3;