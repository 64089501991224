import React, { useContext } from 'react';
import Asection1 from './about/asection1';
import Asection2 from './about/asection2';
import Asection3 from './about/asection3';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../LanguageContext';
import GetInTouch from './croos/getintouch';

const About = () => {
    const languageData = useContext(LanguageContext);
    return (
        <>
            <Helmet>
                <html lang={languageData.about.SEO.language} />
                <title>{languageData.about.SEO.title}</title>
                <meta charSet="utf-8" />
                <link rel="manifest" href="manifest.json" />
                <meta name="description" content={languageData.about.SEO.description} />
                <meta name="viewport" content={languageData.about.SEO.meta.viewport} />
                <link rel="icon" href="favicon.ico" />
            </Helmet>
            <div style={{ marginTop: '90px' }}>
                <Asection1 />
                <Asection2 />
                <Asection3 />
                <GetInTouch />
            </div>
        </>
    );
};

export default About;