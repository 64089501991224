import React, { useState, useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import LanguageMenu from './language/LanguageMenu';
import logo from '../images/name.svg';
import { useLocation } from 'react-router-dom';


const Navbar = () => {
    const languageData = useContext(LanguageContext);
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
    const location = useLocation();

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const handleLanguageButtonClick = () => setIsLanguageModalOpen(!isLanguageModalOpen);
    const closeLanguageModal = () => setIsLanguageModalOpen(false);
    const selectLanguage = (languageCode) => closeLanguageModal();
    const isActive = (path) => {
        const pathSegments = location.pathname.split('/').filter(segment => segment);
        const currentLang = languageData.SEO.language;
    
        // Si path es un string vacío, manejar la página de inicio
        if (path === '') {
            if (location.pathname === '/' && currentLang === 'en') {
                // Página de inicio en inglés
                return true;
            } else if ((currentLang === 'es' || currentLang === 'ca') && pathSegments.length === 1 && pathSegments[0] === currentLang) {
                // Página de inicio en español o catalán
                return true;
            }
            return false;
        }
    
        // En otros casos, compara el primer o segundo segmento (según el idioma) con el path proporcionado
        const relevantPathSegment = currentLang === 'en' ? pathSegments[0] : pathSegments[1] || '';
        return relevantPathSegment === path;
    };
    
 
  
    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{ backgroundColor: '#00263A' }}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" style={{ maxWidth: '200px', height: 'auto' }} />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={!isNavCollapsed}
                    aria-label="Toggle navigation"
                    onClick={handleNavCollapse}
                    style={{ color: '#dcdcdc' }}
                >
                    <i className="bi bi-list" />
                </button>

                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className={`nav-link ${isActive('') ? 'active' : ''}`} href="/" style={{ color: '#dcdcdc', fontWeight: isActive('') ? 'bold' : 'normal', fontSize: '18px' }}>{languageData.menu.home}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${isActive('about') ? 'active' : ''}`} href="/about" style={{ color: '#dcdcdc', fontWeight: isActive('about') ? 'bold' : 'normal', fontSize: '18px' }}>{languageData.menu.about}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${isActive('services') ? 'active' : ''}`} href="/services" style={{ color: '#dcdcdc', fontWeight: isActive('services') ? 'bold' : 'normal', fontSize: '18px' }}>{languageData.menu.services}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${isActive('partners') ? 'active' : ''}`} href="/partners" style={{ color: '#dcdcdc', fontWeight: isActive('partners') ? 'bold' : 'normal', fontSize: '18px' }}>{languageData.menu.partners}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${isActive('contact') ? 'active' : ''}`} href="/contact" style={{ color: '#dcdcdc', fontWeight: isActive('contact') ? 'bold' : 'normal', fontSize: '18px' }}>{languageData.menu.contact}</a>
                        </li>
                    </ul>

                    <LanguageMenu />
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
