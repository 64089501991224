import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import five from '../../images/five.jpg';

const Asection1 = () => {
    const languageData = useContext(LanguageContext);
    
    return (
        <div id="section1" className="container text-center" style={{ marginTop: '7rem' }}>
            <div className="row align-items-center">
                <div className="col">
                    <h1 style={{ display: 'none' }}>{languageData.about.s1.h}</h1>
                    <i className="bi bi-battery-full" style={{ fontSize: '7rem', color: '#50A313' }} />
                    <h2 style={{ fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')() }}><b>{languageData.about.s1.h1}</b></h2>
                    <p className="mt-5"style={{ fontSize: '18px'}}>{languageData.about.s1.p1}</p>
                    <img src={five} alt="fives" style={{ width: '80%', borderRadius: '5%', paddingTop: '10px' }} />
                    <p style={{ fontSize: '18px', textAlign: 'left'}} className="mt-5">{languageData.about.s1.p11}</p>
                </div>
            </div>
        </div>
    )
}

export default Asection1;