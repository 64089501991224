// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { LanguageProvider } from './LanguageContext';
import { getBrowserLanguage } from './middleware/languageUtils';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

import Cookies from 'js-cookie';

function convertToFullLanguageCode(shortCode) {
    switch (shortCode) {
        case 'ca':
            return 'ca_ES';
        case 'es':
            return 'es_ES';
        case 'en':
            return 'en_EN';
        default:
            return 'en_EN';  // Si no es uno de los códigos cortos conocidos, devuelve el código tal cual
    }
}

function Main() {
    const cookieLanguage = Cookies.get('language');
    const initialLanguageCode = cookieLanguage ? 
        convertToFullLanguageCode(cookieLanguage) : 
        getBrowserLanguage() || 'en_EN';
    return (
        <LanguageProvider initialLanguageCode={initialLanguageCode}>
            <Router>
                <App />
            </Router>
        </LanguageProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);

