import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import Alejandro from '../../images/Alejandro.jpg';
import Alex from '../../images/AlexF.jpg';

const Asection2 = () => {
    const languageData = useContext(LanguageContext);
    return (
        <div id="section3" className="container-fluid text-center" style={{ marginTop: '20rem', backgroundColor: '#F7F7F7' }}>
            <div className="container" style={{ paddingTop: '10rem', paddingBottom: '10rem' }}>
                <div className="row mt-4">
                    <div className="col-12 text-center">
                        <h2 style={{ fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')() }}><b>{languageData.about.s2.h}</b></h2>
                        <p style={{ fontSize: '18px'}}>{languageData.about.s2.p}</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-6 col-md-12 mb-4"> {/* Agrega un margen inferior en pantallas md y pequeñas */}
                        <img src={Alejandro} alt="Alejandro" style={{ maxWidth: '200px', borderRadius: '5%' }} />
                        <h2 style={{ textAlign: 'justify' }}>{languageData.about.s2.h1}</h2>
                        <h4 style={{ textAlign: 'left', color: '#50A313' }}>{languageData.about.s2.title1}</h4>
                        <br />
                        <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.about.s2.p1}</p>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <img src={Alex} alt="Alex" style={{ maxWidth: '200px', borderRadius: '5%' }} />
                        <h2 style={{ textAlign: 'justify' }}>{languageData.about.s2.h2}</h2>
                        <h4 style={{ textAlign: 'left', color: '#50A313' }}>{languageData.about.s2.title2}</h4>
                        <br />
                        <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.about.s2.p2}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Asection2;
