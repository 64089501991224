import React, { useContext } from 'react';
import uko from '../../images/uko.svg';
import { LanguageContext } from '../../LanguageContext';

const Section1 = () => {
    const languageData = useContext(LanguageContext);

    const scrollToSection2 = () => {
        const section2 = document.getElementById('section2');
        section2.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <style>
                {`
                @keyframes blink {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0; }
                }

                .blink {
                    animation: blink 2s linear infinite;
                }
            `}
            </style>
            <div style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {/* Estilos responsivos para la imagen */}
                <img src={uko} alt="logo" style={{ maxWidth: '90%', maxHeight: '400px', width: 'auto', height: 'auto' }} />
                <br />
                <div onClick={scrollToSection2}>
                    <i className="bi bi-arrow-down blink" style={{ fontSize: '3rem', color: '#0AB12C' }} />
                </div>
            </div>
        </>
    );
};

export default Section1;
