import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import building from '../../images/building.jpg'

const Ssection8 = () => {
    const languageData = useContext(LanguageContext);
    return (
        <div id="ssection8" className="container-fluid text-center" style={{ marginTop: '20rem', backgroundColor: '#F7F7F7' }}>
            <div className="container" style={{ paddingTop: '10rem', paddingBottom: '10rem' }}>
                <div className="row align-items-center">
                    <div className="col">
                        <img className="img-fluid mt-4" src={building} alt="building" style={{ maxWidth: '320px', borderRadius: '5%' }} />
                        <h2 style={{ fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')() }}><b>{languageData.services.s8.h}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s8.p}</p>
                    </div>
                </div>
                <div className="row mt-4 d-flex align-items-start">
                    <div className="col">
                        <h5 style={{ textAlign: 'center', fontSize: '40px' }}>{languageData.services.s8.ch1}</h5>
                        <br />
                        <div style={{ textAlign: 'left', fontSize: '18px' }}>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c11}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c12}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c13}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c14}</p>
                        </div>
                    </div>
                    <div className="col">
                        <h5 style={{ textAlign: 'center', fontSize: '40px' }}>{languageData.services.s8.ch2}</h5>
                        <br />
                        <div style={{ textAlign: 'left', fontSize: '18px' }}>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c21}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c22}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c23}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c24}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c25}</p>
                        </div>
                    </div>
                    <div className="col">
                        <h5 style={{ textAlign: 'center', fontSize: '40px' }}>{languageData.services.s8.ch3}</h5>
                        <br />
                        <div style={{ textAlign: 'left', fontSize: '18px' }}>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c31}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c32}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c33}</p>
                            <p><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.services.s8.c34}</p>
                        </div>
                    </div>
                </div>
                <div className="row d-flex align-items-center" style={{ marginTop: '5rem' }}>
                    <div className="col d-flex align-items-center justify-content-end">
                        <h5 style={{ textAlign: 'right', fontSize: '40px' }}>
                            {languageData.services.s8.hb}
                        </h5>
                    </div>
                    <div className="col d-flex align-items-center justify-content-start">
                        <p style={{ textAlign: 'left', fontSize: '20px' }}>
                            {languageData.services.s8.pb}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Ssection8;
