import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import screens from '../../images/screens.jpg'

const Asection3 = () => {
    const languageData = useContext(LanguageContext);
    return (
        <div id="section3" className="container-fluid text-center" style={{ marginTop: '20rem' }}>
            <div className="container py-4">
                <div className="row align-items-center">
                    {/* Mover la imagen a la izquierda */}
                    <div className="col-md-6">
                        <img src={screens} alt="office" style={{ width: '90%', borderRadius: '5%' }} />
                    </div>
                    {/* Mover el texto a la derecha */}
                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-start h-100">
                            <h2 style={{
                                fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')(),
                                textAlign: 'left'
                            }}><b>{languageData.about.s3.h}</b></h2>
                            <br />
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.about.s3.p1}</p>
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.about.s3.p2}</p>
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.about.s3.p3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Asection3;