import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../LanguageContext';

const SContact1 = () => {
    const languageData = useContext(LanguageContext);
    const srcUrl = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23941.25111321413!2d2.212945!3d41.403268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a3433b23ca11%3A0x60d419e333cfa7b9!2zUC7CuiBkZSBHYXJjw61hIEZhcmlhLCA0OSwgU2FudCBNYXJ0w60sIDA4MDE5IEJhcmNlbG9uYSwgRXNwYcOxYQ!5e0!3m2!1ses!2sus!4v1705180144673!5m2!1ses!2sus`;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://leads.ukotek.com', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setShowSuccessModal(true);
                setFormData({ name: '', email: '', subject: '', message: '' }); // Limpiar formulario
            } else {
                throw new Error('Error al enviar el formulario');
            }
        } catch (error) {
            setShowErrorModal(true);
        }
    };

    return (
        <div id="scontact1" className="container-fluid text-center" style={{ marginTop: '10rem' }}>
            <div className="container py-4">
                <div className="row">
                    {/* Mover la imagen a la izquierda */}
                    <div className="col-md-6 mb-4 rounded" style={{ backgroundColor: '#f8f9fa' }}>
                        <h1 style={{ fontSize: '60px' }}><b>{languageData.contact.s1.h}</b></h1>
                        <p className="mt-5" style={{ fontSize: '20px', textAlign: 'justify' }}>{languageData.contact.s1.p}</p>
                        <p className="mt-4" style={{ fontSize: '24px', textAlign: 'left' }}><b>{languageData.contact.s1.pp1}</b></p>
                        <p style={{ fontSize: '20px', textAlign: 'left' }}> {/* Utiliza una etiqueta <p> con clase text-left para alinear el contenido a la izquierda */}
                            <a href="tel:+34697431892" >+34 697 43 18 92</a>
                        </p>
                        <p className="mt-4" style={{ fontSize: '24px', textAlign: 'left' }}><b>{languageData.contact.s1.pp2}</b></p>
                        <p style={{ fontSize: '20px', textAlign: 'left' }}> {/* Utiliza una etiqueta <p> con clase text-left para alinear el contenido a la izquierda */}
                            <a href="mailto:info@ukotek.com" >info@ukotek.com</a>
                        </p>
                        <p className="mt-4" style={{ fontSize: '24px', textAlign: 'left' }}><b>{languageData.contact.s1.pp3}</b></p>
                        <iframe
                            title="Ubicación"
                            width="100%"
                            height="450"
                            frameBorder="0"
                            style={{ border: 0, borderRadius: '5%' }}
                            src={srcUrl}
                            allowFullScreen
                        />
                    </div>
                    {/* 
                    <div className="col-md-6 d-flex align-items-center mt-2">
                        <div className="w-100">
                            <h1 style={{ fontSize: '60px' }}><b>{languageData.contact.s1.pp1}</b></h1>
                            <form
                                onSubmit={handleSubmit}
                            >
                                <input type="text" name="Name" className="form-control" id="Name" aria-describedby="NameHelp" placeholder={languageData.contact.s1.pp4} />
                                <input type="email" name="email" className="form-control mt-3" id="email" aria-describedby="emailHelp" placeholder={languageData.contact.s1.pp5} />
                                <input type="text" name="subject" className="form-control mt-3" id="subject" aria-describedby="subjectHelp" placeholder={languageData.contact.s1.pp6} />
                                <textarea name="message" className="form-control mt-3" id="message" rows="4" placeholder={languageData.contact.s1.pp7}></textarea>
                                <button type="submit" className="btn btn-success btn-lg mt-3">{languageData.contact.s1.pp8}</button>
                            </form>
                        </div>
                    </div>*/}
                </div> 
            </div>
        </div >
    );
}

export default SContact1;
