import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';

const SSection7 = () => {
    const languageData = useContext(LanguageContext);

    return (
        <div id="ssection7" className="container-fluid text-center" style={{ marginTop: '20rem' }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col">
                        <h2 style={{ fontSize: '40px' }}><b>{languageData.services.s7.h}</b></h2>
                        <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s7.p}</p>
                        <a data-bs-toggle="collapse" href="#optimitze" role="button" aria-expanded="false" style={{ fontSize: '18px', textAlign: 'left', display: 'block', color: '#212529', textDecoration: 'none' }}>
                            <h5 style={{ fontSize: '30px' }}><i className="bi bi-diamond-fill" /><b> {languageData.services.s7.ah1}</b></h5>
                        </a>
                        <div className="collapse" id="optimitze" style={{ textAlign: 'left' }}>
                            <p style={{ fontSize: '18px', marginLeft: '40px' }}>
                                {languageData.services.s7.ap1}
                            </p>
                        </div>
                        <a data-bs-toggle="collapse" href="#priorize" role="button" aria-expanded="false" style={{ fontSize: '18px', textAlign: 'left', display: 'block', color: '#212529', textDecoration: 'none' }}>
                            <h5 style={{ fontSize: '30px' }}><i className="bi bi-diamond-fill" /><b> {languageData.services.s7.ah2}</b></h5>
                        </a>
                        <div className="collapse" id="priorize" style={{ textAlign: 'left' }}>
                            <p style={{ fontSize: '18px', marginLeft: '40px' }}>
                                {languageData.services.s7.ap21}
                            </p>
                            <p style={{ fontSize: '18px', marginLeft: '40px' }}>
                                {languageData.services.s7.ap22}
                            </p>
                        </div>
                        <a data-bs-toggle="collapse" href="#discover" role="button" aria-expanded="false" style={{ fontSize: '18px', textAlign: 'left', display: 'block', color: '#212529', textDecoration: 'none' }}>
                            <h5 style={{ fontSize: '30px' }}><i className="bi bi-diamond-fill" /><b> {languageData.services.s7.ah3}</b></h5>
                        </a>
                        <div className="collapse" id="discover" style={{ textAlign: 'left' }}>
                            <p style={{ fontSize: '18px', marginLeft: '40px' }}>
                                {languageData.services.s7.ap31}
                            </p>
                            <p style={{ fontSize: '18px', marginLeft: '40px' }}>
                                {languageData.services.s7.ap32}
                            </p>
                        </div>
                        <a data-bs-toggle="collapse" href="#validate" role="button" aria-expanded="false" style={{ fontSize: '18px', textAlign: 'left', display: 'block', color: '#212529', textDecoration: 'none' }}>
                            <h5 style={{ fontSize: '30px' }}><i className="bi bi-diamond-fill" /><b> {languageData.services.s7.ah4}</b></h5>
                        </a>
                        <div className="collapse" id="validate" style={{ textAlign: 'left' }}>
                            <p style={{ fontSize: '18px', marginLeft: '40px'}}>
                                {languageData.services.s7.ap41}
                            </p>
                            <p style={{ fontSize: '18px', marginLeft: '40px' }}>
                                {languageData.services.s7.ap42}
                            </p>
                            <p style={{ fontSize: '18px', marginLeft: '40px' }}>
                                {languageData.services.s7.ap43}
                            </p>
                            <p style={{ fontSize: '18px', marginLeft: '40px' }}>
                                {languageData.services.s7.ap44}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SSection7;
