import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const year = new Date().getFullYear();
    const languageData = useContext(LanguageContext);
    const navigate = useNavigate();

    const handleNavigate = (path) => (event) => {
        event.preventDefault(); // Previene el comportamiento predeterminado del enlace
        navigate(path, { replace: true, state: { scroll: 'top' } }); // Usa navigate para ir a la ruta deseada
    };

    // Construye las URLs dependiendo del idioma
    const currentLang = languageData.SEO.language;
    const adviseUrl = currentLang === 'en' ? '/advise' : `/${currentLang}/advise`;
    const cookiesUrl = currentLang === 'en' ? '/cookies' : `/${currentLang}/cookies`;

    return (
        <footer className="footer py-3" style={{ backgroundColor: '#00263A', marginTop: '20rem' }}>
            <div className="container">
                <p className="text-center text-white mb-3" style={{ fontWeight: 'bold' }}>
                    Ukotek © {year}
                </p>
                <div className="row">
                    <div className="col-12 col-md-6 text-center text-md-left mb-2 mb-md-0">
                        {/* Usa handleNavigate con la URL adecuada */}
                        <a href={adviseUrl} className="text-white" onClick={handleNavigate(adviseUrl)}>{languageData.advise.h}</a>
                    </div>
                    <div className="col-12 col-md-6 text-center text-md-right">
                        {/* Usa handleNavigate con la URL adecuada */}
                        <a href={cookiesUrl} className="text-white" onClick={handleNavigate(cookiesUrl)}>{languageData.cookiePolicy.title}</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
