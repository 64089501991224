import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from "react-router-dom";
import Header from './components/header';
import Home from './components/home';
import Footer from './components/footer';
import CookieConsentDialog from './components/cookie/CookieConsentDialog'; // Importar cookie modal
import Services from './components/services';
import About from './components/about';
import Contact from './components/contact';
import Partners from './components/partners';
import Advise from './components/footer/advise';
import Cookies from './components/footer/cookies';

function App() {
  const languageData = useContext(LanguageContext);
  const location = useLocation();
  const navigate = useNavigate();
  const langPath = languageData.SEO.language !== 'en' ? `/${languageData.SEO.language}` : '';

  useEffect(() => {
    const lang = languageData.SEO.language;
    const currentPath = location.pathname;
    const pathParts = currentPath.split('/').filter(part => part);

    if (lang !== 'en' && pathParts[0] !== lang) {
      if (pathParts[0] === 'es' || pathParts[0] === 'ca') {
        pathParts[0] = lang;
      } else {
        pathParts.unshift(lang);
      }
    }

    const newPath = `/${pathParts.join('/')}`;

    if (newPath !== currentPath) {
      navigate(newPath);
    }
  }, []);




  return (
    <>
      <Helmet>
        <html lang={languageData.SEO.language} />
        <title>{languageData.SEO.title}</title>
        <meta charSet="utf-8" />
        <link rel="manifest" href="manifest.json" />
        <meta name="description" content={languageData.SEO.description} />
        <meta name="viewport" content={languageData.SEO.meta.viewport} />
        <link rel="icon" href="favicon.ico" />
        <link rel="stylesheet" href="/css/standard.css" />
        <link rel="canonical" href={`https://ukotek.com${languageData.SEO.language !== 'en' ? '/' + languageData.SEO.language : ''}${location.pathname}`} />
        <link rel="alternate" href={`https://ukotek.com${location.pathname}`} hreflang="en" />
        <link rel="alternate" href={`https://ukotek.com/es${location.pathname}`} hreflang="es" />
        <link rel="alternate" href={`https://ukotek.com/ca${location.pathname}`} hreflang="ca" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BH65930DHQ"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-BH65930DHQ');
          `}
        </script>
      </Helmet>
      <Header />
      <div className="d-flex flex-column min-vh-100"> {/* Ajusta el margen superior según la altura de tu Header */}
        <div className="flex-grow-1">
          <CookieConsentDialog />
          <Routes>
            <Route path={`${langPath}/*`} element={<Outlet />}>
              <Route index element={<Home />} />
              <Route path="services" element={<Services />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="partners" element={<Partners />} />
              <Route path="advise" element={<Advise />} />
              <Route path="cookies" element={<Cookies />} />
            </Route>
          </Routes>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;