// src/LanguageContext.js
import React, { useState, useEffect, createContext } from 'react';
import { loadLanguageFile } from './middleware/languageUtils';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children, initialLanguageCode }) => {
  const [languageCode, setLanguageCode] = useState(initialLanguageCode);
  const [languageData, setLanguageData] = useState(null);
  useEffect(() => {
    loadLanguageFile(languageCode).then(data => {
      setLanguageData(data.default);
    });
    // También actualiza la cookie cada vez que el languageCode cambie
  }, [languageCode]);

  const changeLanguage = (newLanguageCode) => {
    setLanguageCode(newLanguageCode);
  };

  if (!languageData) return null;  // Renderiza null mientras se cargan los datos del idioma

  return (
    <LanguageContext.Provider value={{ ...languageData, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
