import React from 'react';
import Section1 from './home/section1';
import Section2 from './home/section2';
import Section3 from './home/section3';
import Section4 from './home/section4';
import Section5 from './home/section5';
import GetInTouch from './croos/getintouch';

const Home = () => {

  return (
    <div style={{ marginTop: '90px' }}>
      <Section1 />
      <Section2 />
      <Section3 /> 
      <Section4 /> 
      <Section5 />
      <GetInTouch />
    </div>
  );
};

export default Home;

