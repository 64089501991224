import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import Cookies from 'js-cookie';

const LanguageMenu = () => {
  const languageData = useContext(LanguageContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentLanguage, setCurrentLanguage] = useState(Cookies.get('language') || 'en');

  useEffect(() => {
    setCurrentLanguage(Cookies.get('language') || 'en');

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getLanguageUrl = (langCode) => {
    const basePath = window.location.pathname.replace(/^\/(es|ca)/, '');
    return langCode === 'en_EN' ? window.location.origin + basePath : window.location.origin + '/' + langCode.split('_')[0].toLowerCase() + basePath;
  };

  const handleSelectLanguage = (languageCode) => {
    let lang = languageCode.split('_')[0].toLowerCase();
    Cookies.set('language', lang, { expires: 365 });
    setCurrentLanguage(lang);
  };

  const isLanguageActive = (lang) => currentLanguage === lang;

  return (
    <>
      <div className={`dropdown ${showDropdown ? 'show' : ''}`}>
        <button className="btn btn-outline-secondary ms-2 dropdown-toggle" type="button"
          onClick={() => setShowDropdown(!showDropdown)} style={{ border: 'none' }}>
          <i className="bi bi-translate" style={{ color: '#dcdcdc' }}></i>
        </button>
        {isMobile ? (
          // Menú desplegable para móviles
          <div className={`dropdown-menu${showDropdown ? ' show' : ''}`} style={{ position: 'absolute', left: 0, top: '100%' }}>
            <a className={`dropdown-item ${isLanguageActive('ca') ? 'active' : ''}`} href={getLanguageUrl('ca_ES')} onClick={() => handleSelectLanguage('ca_ES')}>{languageData.options.catalan}</a>
            <a className={`dropdown-item ${isLanguageActive('es') ? 'active' : ''}`} href={getLanguageUrl('es_ES')} onClick={() => handleSelectLanguage('es_ES')}>{languageData.options.spanish}</a>
            <a className={`dropdown-item ${isLanguageActive('en') ? 'active' : ''}`} href={getLanguageUrl('en_EN')} onClick={() => handleSelectLanguage('en_EN')}>{languageData.options.english}</a>
          </div>
        ) : (
          // Menú desplegable para PC
          <div className={`dropdown-menu${showDropdown ? ' show' : ''}`} style={{ position: 'absolute', right: 0, top: '100%' }}>
            <a className={`dropdown-item ${isLanguageActive('ca') ? 'active' : ''}`} href={getLanguageUrl('ca_ES')} onClick={() => handleSelectLanguage('ca_ES')}>{languageData.options.catalan}</a>
            <a className={`dropdown-item ${isLanguageActive('es') ? 'active' : ''}`} href={getLanguageUrl('es_ES')} onClick={() => handleSelectLanguage('es_ES')}>{languageData.options.spanish}</a>
            <a className={`dropdown-item ${isLanguageActive('en') ? 'active' : ''}`} href={getLanguageUrl('en_EN')} onClick={() => handleSelectLanguage('en_EN')}>{languageData.options.english}</a>
          </div>
        )}
      </div>
    </>

  );
};

export default LanguageMenu;
