import React, { useContext, useEffect } from 'react';
import dosfa from '../../images/2fa.jpg';
import { LanguageContext } from '../../LanguageContext';
import { useNavigate } from 'react-router-dom';
import '../croos/button.css';

const Section2 = () => {
  const languageData = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Obtén el código de idioma actual (p. ej., 'en', 'es', 'ca')
    const currentLang = languageData.SEO.language;
    // Construye la URL dependiendo del idioma
    const serviceUrl = currentLang === 'en' ? '/services#ssection1' : `/${currentLang}/services#ssection1`;
    // Redirecciona a la URL
    navigate(serviceUrl);
  };

  const adjustTextSize = () => {
    const newSize = window.innerWidth >= 768 ? '60px' : '40px';
    document.querySelectorAll('.dynamic-text-size').forEach(element => {
      element.style.fontSize = newSize;
    });
  };

  useEffect(() => {
    adjustTextSize();
    window.addEventListener('resize', adjustTextSize);

    return () => window.removeEventListener('resize', adjustTextSize);
  }, []);

  return (
    <div id="section2" className="container-fluid text-center mt-4" style={{ backgroundColor: '#F7F7F7' }}>
      <div className="container" style={{ paddingTop: '10rem', paddingBottom: '10rem' }}>
        <div className="row align-items-center">
          <div className="col-md-6 order-md-2">
            <img src={dosfa} alt="2fa" style={{ width: '100%', borderRadius: '5%' }} />
          </div>
          <div className="col-md-6">
            {/* Modificado para alinear elementos al inicio (izquierda) */}
            <div className="d-flex flex-column align-items-start h-100">
            <h1 className="dynamic-text-size"><b>{languageData.home.s2.h}</b></h1>
              <p className="mt-4" style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.home.s2.p1}</p>
              <p style={{ fontSize: '18px', textAlign: 'left' }}><b>{languageData.home.s2.p2}</b></p>
              <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.home.s2.p3}</p>
              <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.home.s2.p4}</p>
              <button type="button" className="btn-custom-success mt-4" onClick={handleButtonClick}>{languageData.home.s2.b}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
