import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { useNavigate } from 'react-router-dom';
import '../croos/button.css';

const GetInTouch = () => {
    const languageData = useContext(LanguageContext);
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // Obtén el código de idioma actual (p. ej., 'en', 'es', 'ca')
        const currentLang = languageData.SEO.language;
        // Construye la URL dependiendo del idioma
        const serviceUrl = currentLang === 'en' ? '/contact' : `/${currentLang}/contact`;

        // Redirecciona a la URL
        navigate(serviceUrl);
    };
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center" style={{ marginTop: '20rem' }}> {/* Centrar vertical y horizontalmente */}
                <div className="col-6 text-center">
                    <i style={{ color: 'green', fontSize: '60px' }} className="bi bi-chat"></i>
                    <h2 style={{
                        fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')()
                    }}><b>{languageData.intouch.h}</b></h2>
                    <p className="mt-4" style={{ fontSize: '18px' }}>{languageData.intouch.p}</p>
                    <button type="button" className="btn-custom-success mt-4" onClick={handleButtonClick}>{languageData.menu.contact}</button>
                </div>
            </div>
        </div>
    );
}

export default GetInTouch;