import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import office from '../../images/office.jpg'
import '../croos/button.css';
import { useNavigate } from 'react-router-dom';

const SSection2 = () => {
    const languageData = useContext(LanguageContext);
    const navigate = useNavigate();
    const handleButtonClick = () => {
        // Obtén el código de idioma actual (p. ej., 'en', 'es', 'ca')
        const currentLang = languageData.SEO.language;
        // Construye la URL dependiendo del idioma
        const serviceUrl = currentLang === 'en' ? '/contact' : `/${currentLang}/contact`;

        // Redirecciona a la URL
        navigate(serviceUrl);
    };
    return (
        <div id="ssection2" className="container-fluid text-center" style={{ marginTop: '20rem', backgroundColor: '#F7F7F7' }}>
            <div className="container" style={{ paddingTop: '10rem', paddingBottom: '10rem' }}>
                <div className="row align-items-center">
                    {/* Mover la imagen a la izquierda */}
                    <div className="col-md-6">
                        <img src={office} alt="office" style={{ width: '90%', borderRadius: '5%' }} />
                    </div>
                    {/* Mover el texto a la derecha */}
                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-start h-100">
                            <h2 style={{
                                fontSize: (() => window.innerWidth >= 768 ? '60px' : '40px')(),
                                textAlign: 'left'
                            }}><b>{languageData.services.s2.h}</b></h2>
                            <br />
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s2.p1}</p>
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s2.p2}</p>
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s2.p3}</p>
                            <p style={{ fontSize: '18px', textAlign: 'left' }}>{languageData.services.s2.p4}</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col text-center">
                        <div style={{ textAlign: 'left', display: 'inline-block', maxWidth: '100%' }}>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.services.s2.t1}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.services.s2.t2}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.services.s2.t3}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.services.s2.t4}</p>
                            <p style={{ fontSize: '18px' }}><i className="bi bi-caret-right-fill" style={{ color: '#50A313' }} /> {languageData.services.s2.t5}</p>
                            <div className="text-start"> {/* Alinea el botón a la izquierda */}
                                <button type="button" className="btn-custom-success mt-4" onClick={handleButtonClick}>{languageData.services.s1.b}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SSection2;
