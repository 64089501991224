import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { useNavigate } from 'react-router-dom';
import binario from '../../images/binario.jpg';

const Section4 = () => {
    const languageData = useContext(LanguageContext);
    const navigate = useNavigate();

    const handleButtonClick = (sectionId) => {
        const currentLang = languageData.SEO.language;
        const serviceUrl = `${currentLang === 'en' ? '/services' : `/${currentLang}/services`}#${sectionId}`;
        navigate(serviceUrl);
    };
    
    // Función para ajustar el tamaño del texto
    const adjustTextSize = () => {
        const newSize = window.innerWidth >= 768 ? '60px' : '40px';
        document.querySelectorAll('.dynamic-text-size').forEach(element => {
            element.style.fontSize = newSize;
        });
    };

    useEffect(() => {
        // Ajustar el tamaño del texto al montar y al cambiar el tamaño de la ventana
        adjustTextSize();
        window.addEventListener('resize', adjustTextSize);

        // Limpiar el event listener al desmontar el componente
        return () => window.removeEventListener('resize', adjustTextSize);
    }, []);
   
    return (
        <div id="section4" className="container-fluid text-center" style={{ marginTop: '20rem', backgroundColor: '#F7F7F7' }}>
            <div className="container" style={{ paddingTop: '10rem', paddingBottom: '10rem' }}>
                <div className="row align-items-center">
                    <div className="col">
                    <h2 className="dynamic-text-size"><b>{languageData.home.s4.h}</b></h2>
                    </div>
                </div>
                <div className="row mt-4 d-flex align-items-stretch">
                    <div className="col p-3">
                        <div className="card h-100">
                            <div className="card-header">
                                <h3><b>{languageData.home.s4.p111}</b></h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <p style={{ fontSize: '18px', textAlign: 'left', flex: 1 }}>{languageData.home.s4.p112}</p>
                                <div className="mt-auto" style={{ textAlign: 'left' }}>
                                    <button type="button" className="btn btn-outline-info" onClick={() => handleButtonClick('ssection1')}>{languageData.home.s4.pread}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col p-3">
                        <div className="card h-100">
                            <div className="card-header">
                                <h3><b>{languageData.home.s4.p121}</b></h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <p style={{ fontSize: '18px', textAlign: 'left', flex: 1 }}>{languageData.home.s4.p122}</p>
                                <div className="mt-auto" style={{ textAlign: 'left' }}>
                                    <button type="button" className="btn btn-outline-info" onClick={() => handleButtonClick('ssection2')}>{languageData.home.s4.pread}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col p-3">
                        <div className="card h-100">
                            <div className="card-header">
                                <h3><b>{languageData.home.s4.p131}</b></h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <p style={{ fontSize: '18px', textAlign: 'left', flex: 1 }}>{languageData.home.s4.p132}</p>
                                <div className="mt-auto" style={{ textAlign: 'left' }}>
                                    <button type="button" className="btn btn-outline-info" onClick={() => handleButtonClick('ssection6')}>{languageData.home.s4.pread}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12 col-md-4 p-3"> {/* Asegúrate de que cada tarjeta ocupe el ancho completo en pantallas pequeñas y 1/3 en pantallas medianas */}
                        <div className="card h-100">
                            <div className="card-header">
                                <h3><b>{languageData.home.s4.p211}</b></h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <p style={{ fontSize: '18px', textAlign: 'left', flex: 1 }}>{languageData.home.s4.p212}</p>
                                <div className="mt-auto" style={{ textAlign: 'left' }}>
                                    <button type="button" className="btn btn-outline-info" onClick={() => handleButtonClick('ssection4')}>{languageData.home.s4.pread}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 p-3">
                        <div className="card h-100">
                            <div className="card-header">
                                <h3><b>{languageData.home.s4.p221}</b></h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <p style={{ fontSize: '18px', textAlign: 'left', flex: 1 }}>{languageData.home.s4.p222}</p>
                                <div className="mt-auto" style={{ textAlign: 'left' }}>
                                    <button type="button" className="btn btn-outline-info" onClick={() => handleButtonClick('ssection7')}>{languageData.home.s4.pread}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 p-3">
                        <div className="card h-100">
                            <div className="card-header">
                                <h3><b>{languageData.home.s4.p231}</b></h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <p style={{ fontSize: '18px', textAlign: 'left', flex: 1 }}>{languageData.home.s4.p232}</p>
                                <div className="mt-auto" style={{ textAlign: 'left' }}>
                                    <button type="button" className="btn btn-outline-info" onClick={() => handleButtonClick('ssection8')}>{languageData.home.s4.pread}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center" style={{ marginTop: '10rem' }}>
                    <div className="col">
                        <h2 className="dynamic-text-size"><b>{languageData.home.s4.h2}</b></h2>
                    </div>
                </div>
                <div className="container text-center">
                    <div className="row align-items-center mt-4">
                        <div className="col-md-4 mb-4">
                            <img src={binario} alt="Binary" style={{ width: '90%', borderRadius: '5%' }} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <h4 style={{ textAlign: 'left' }}>{languageData.home.p811}</h4>
                            <br />
                            <div style={{ textAlign: 'left' }}>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p312}</p>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p313}</p>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p314}</p>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p315}</p>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p316}</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <h4 style={{ textAlign: 'left' }}>{languageData.home.p821}</h4>
                            <br />
                            <div style={{ textAlign: 'left' }}>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p322}</p>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p323}</p>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p324}</p>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p325}</p>
                                <p style={{ fontSize: '18px' }}><i className="bi bi-check-circle" style={{ color: 'green' }} /> {languageData.home.s4.p326}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Section4;