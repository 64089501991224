import React, { useContext } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { LanguageContext } from '../../LanguageContext';

// Importa los logos
import logo1 from '../../images/partners/cloudflare.svg'
import logo2 from '../../images/partners/crowdstrike.svg';
import logo3 from '../../images/partners/microsoft.svg';
import logo4 from '../../images/partners/wazuh.svg';
import logo5 from '../../images/partners/semperis.svg';
import logo6 from '../../images/partners/picus.svg';
// ...otros logos...

const Section5 = () => {
    const languageData = useContext(LanguageContext);
    const responsive = {
        superLargeDesktop: {
            // Pantallas de escritorio grandes
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            // Pantallas de escritorio
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            // Tablets
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            // Dispositivos móviles
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const imageStyle = {
        width: '80%', // Establece un ancho fijo para todas las imágenes
        maxHeight: '100px', // Establece una altura máxima
        objectFit: 'contain', // Mantiene las proporciones de la imagen
        margin: 'auto', // Centra la imagen en su contenedor
    };


    const semperisStyle = {
        // Estilo específico para el logo de Semperis
        ...imageStyle,         // Aplica los estilos comunes
        marginLeft: '160px',
    };

    return (
        <div id="section2" className="container text-center" style={{ marginTop: '20rem' }}>
            <div className="row align-items-center">
                <div className="col">
                    <h2 style={{ fontSize: '40px' }}>{languageData.home.s5.h}</h2>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col">
                    <div className="container my-5">
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            showDots={true}
                        >
                            <img src={logo1} alt="Cloudflare" style={imageStyle} />
                            <img src={logo2} alt="Crowdstrike" style={imageStyle} />
                            {/*<img src={logo3} alt="Microsoft" style={imageStyle} />*/}
                            <img src={logo4} alt="Wazuh" style={imageStyle} />
                            <img src={logo5} alt="Semperis" style={semperisStyle} />
                            <img src={logo6} alt="Picus" style={imageStyle} />
                            {/* ...otros logos... */}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section5;
