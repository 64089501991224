import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../LanguageContext';
import Cookies from 'js-cookie';
import cookieIcon from '../../images/cookie.svg';

const CookieConsentDialog = () => {
    const languageData = useContext(LanguageContext);
    const [minimized, setMinimized] = useState(false);
    const [optionsEnabled, setOptionsEnabled] = useState(true);

    useEffect(() => {
        const cookieAccepted = Cookies.get('cookie_accepted');
        const optionsCookie = Cookies.get('options_enabled');
        setOptionsEnabled(optionsCookie !== 'false');
        setMinimized(cookieAccepted === 'true');
    }, []);

    const handleAcceptCookie = () => {
        Cookies.set('cookie_accepted', 'true', { expires: 365 });
        Cookies.set('options_enabled', optionsEnabled ? 'true' : 'false', { expires: 365 });
        setMinimized(true);
    };

    const handleCancel = () => {
        Cookies.set('cookie_accepted', 'true', { expires: 365 });
        Cookies.set('options_enabled', 'false', { expires: 365 });
        setMinimized(true);
    };

    const handleToggleMinimize = () => {
        const optionsCookie = Cookies.get('options_enabled');
        setOptionsEnabled(optionsCookie !== 'false');  // Actualizar el estado según el valor almacenado en las cookies
        setMinimized(!minimized);
    };

    if (minimized) {
        return (
            <div style={{ position: 'fixed', bottom: '20px', left: '20px', cursor: 'pointer' }} onClick={handleToggleMinimize}>
                <img src={cookieIcon} alt="Cookie" style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }

    return (
        <div aria-live="polite" aria-atomic="true" style={{ position: 'relative', minHeight: '100px' }}>
            <div className="toast show" style={{
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                margin: '1rem',
                maxWidth: 'calc(100% - 2rem)',
                zIndex: 1050  // Ajustado para asegurar que esté por encima de otros elementos
            }}>
                <div className="toast-header">
                    <strong className="mr-auto">Cookie Consent</strong>
                </div>
                <div className="toast-body">
                    {languageData.cookie.description} {' '}
                    <a href="https://cookiesandyou.com" target="_blank" rel="noopener noreferrer">
                        {languageData.cookie.que}
                    </a>
                    <br />
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="functionals" checked disabled />
                        <label className="form-check-label" htmlFor="functionals">
                            {languageData.cookie.functionals}
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="options" checked={optionsEnabled} onChange={() => setOptionsEnabled(!optionsEnabled)} />
                        <label className="form-check-label" htmlFor="options">
                            {languageData.cookie.options}
                        </label>
                    </div>
                    <div className="mt-2 pt-2 border-top">
                        <button className="btn btn-success btn-sm" onClick={handleAcceptCookie} style={{ marginRight: '10px' }}>
                            <span>👍</span> {languageData.cookie.accept}
                        </button>
                        <button className="btn btn-danger btn-sm ml-2" onClick={handleCancel}>
                            {languageData.cookie.cancel}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookieConsentDialog;
